
import { Options, Vue } from "vue-class-component";
import { ApplicationStatus } from "@/middlewares/enum";
import { Notify, Toast } from "vant";
import moment from "moment";
@Options({
  components: {
    [Notify.Component.name]: Notify.Component,
  },
})
export default class ReArchivedReportlist extends Vue {
  private applicationStatus = ApplicationStatus;
  private refreshing = false;
  private show = false;
  private passing = false;
  private returning = false;
  private approvalForm: any = {
    id: "",
    applicationIds: [],
    approver: "",
    approveTime: "",
    approvalOpinion: "",
    status: "",
    remark: "",
    checkOrgId: "",
  };
  private loading = false;
  private finished = false;
  private applyList: any = [];
  private pageIndex = 1;
  private searchDateType = "today";
  private serchForm: any = {
    sampleCode: "",
    sampleName: "",
  };
  private findApplyList() {
    if (this.refreshing) {
      this.applyList = [];
      this.refreshing = false;
    }
    this.loading = true;
    const url = "/api/lims/application/getAppPageList";
    this.axios
      .get(url, {
        params: {
          sampleCode: this.serchForm.sampleCode,
          sampleName: this.serchForm.sampleName,
          appStatus: "ge:65",
          size: 20,
          current: this.pageIndex,
        },
      })
      .then((res: any) => {
        //关闭加载
        this.loading = false;
        if (res.status.code !== 1) {
          this.$toast.fail(res.status.msg);
        } else {
          for (const record of res.data.records) {
            record.checked = false;
          }
          this.applyList = this.applyList.concat(res.data.records);
        }
        if (
          !res.data.records ||
          res.data.records.length === 0 ||
          this.applyList.length === res.data.total
        ) {
          this.finished = true;
        } else {
          this.pageIndex++;
        }
      })
      .catch((err) => {
        this.$toast.fail(err);
      });
  }

  private closePopup() {
    this.approvalForm = {
      id: "",
      applicationIds: [],
      approver: "",
      approveTime: "",
      approvalOpinion: "",
      status: "",
      remark: "",
      checkOrgId: "",
    };
  }
  private doSearch() {
    this.refreshing = true;
    this.onRefresh();
    const ref: any = this.$refs["dropdownSearch"];
    ref.toggle(false);
  }

  private getApplicationStatusDesc(scope: any) {
    for (const item of this.applicationStatus) {
      if (scope === item.code) {
        return item.name;
      }
    }
  }

  private formatDataTime(time: any) {
    return moment(time).format("YYYY-MM-DD");
  }
  //下拉刷新
  private onRefresh() {
    // 清空列表数据
    this.finished = false;
    this.pageIndex = 1;
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.findApplyList();
  }
  //重置查询
  private resetSearch() {
    this.searchDateType = "today";
    this.serchForm = {
      sampleCode: "",
      sampleName: "",
    };
    this.refreshing = true;
    this.onRefresh();
    const ref: any = this.$refs["dropdownSearch"];
    ref.toggle(false);
  }
}


import { Options, Vue } from "vue-class-component";
import { collectStatus, eCollectStatus } from "@/middlewares/enum";
import NoArchivedReportlist from "@/views/report/NoArchivedReportlist.vue";
import ReArchivedReportlist from "@/views/report/ReArchivedReportlist.vue";
@Options({
  components: {
    NoArchivedReportlist,
    ReArchivedReportlist,
  },
})
export default class ArchivedReportlist extends Vue {
  public created() {
    (window as any).callJS2 = (msg: any) => {
      //和flutter一样的方法
      return this.callJS(msg);
    };
    console.log(localStorage.length);
    console.log(localStorage.getItem("userInfo"));
    console.log(this.$store.state.userInfo);
    if (this.$store.state.userInfo) {
      localStorage.setItem("token", this.$store.state.userInfo.token);
    }
  }
  private callJS(message: any) {
    console.log(message);
    return "call js ok";
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "noArchivedReportlist" }
const _hoisted_2 = { style: {"padding":"10px 16px 5px 16px"} }
const _hoisted_3 = { style: {"padding":"5px 16px 10px 16px"} }
const _hoisted_4 = { class: "col-info" }
const _hoisted_5 = { class: "num" }
const _hoisted_6 = { class: "row-barcode" }
const _hoisted_7 = { class: "txt-right col-status" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "txt-right" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "txt-right" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "txt-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!
  const _component_van_button = _resolveComponent("van-button")!
  const _component_van_dropdown_item = _resolveComponent("van-dropdown-item")!
  const _component_van_dropdown_menu = _resolveComponent("van-dropdown-menu")!
  const _component_van_checkbox = _resolveComponent("van-checkbox")!
  const _component_van_tag = _resolveComponent("van-tag")!
  const _component_van_icon = _resolveComponent("van-icon")!
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_list = _resolveComponent("van-list")!
  const _component_van_pull_refresh = _resolveComponent("van-pull-refresh")!
  const _component_van_action_bar_button = _resolveComponent("van-action-bar-button")!
  const _component_van_action_bar = _resolveComponent("van-action-bar")!
  const _component_ArchivedReport = _resolveComponent("ArchivedReport")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_dropdown_menu, null, {
      default: _withCtx(() => [
        _createVNode(_component_van_dropdown_item, {
          title: "筛选",
          ref: "dropdownSearch"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_van_cell_group, { title: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_van_field, {
                  modelValue: _ctx.serchForm.sampleCode,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.serchForm.sampleCode) = $event)),
                  label: "采集编号",
                  "input-align": "right",
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_van_button, {
                type: "danger",
                block: "",
                round: "",
                onClick: _ctx.doSearch
              }, {
                default: _withCtx(() => [
                  _createTextVNode("查询")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_van_button, {
                block: "",
                round: "",
                onClick: _ctx.resetSearch
              }, {
                default: _withCtx(() => [
                  _createTextVNode("重置")
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          _: 1
        }, 512)
      ]),
      _: 1
    }),
    _createVNode(_component_van_pull_refresh, {
      modelValue: _ctx.refreshing,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.refreshing) = $event)),
      onRefresh: _ctx.onRefresh
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_list, {
          loading: _ctx.loading,
          "onUpdate:loading": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loading) = $event)),
          finished: _ctx.finished,
          "finished-text": "没有更多了",
          onLoad: _ctx.findApplyList
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.applyList, (item, idx) => {
              return (_openBlock(), _createBlock(_component_van_cell, {
                key: idx,
                class: "col-cell"
              }, {
                title: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(idx + 1), 1),
                    _createVNode(_component_van_checkbox, {
                      modelValue: item.checked,
                      "onUpdate:modelValue": ($event: any) => ((item.checked) = $event),
                      onClick: _ctx.checkOneChange
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onClick"]),
                    _createElementVNode("p", _hoisted_6, [
                      _createVNode(_component_van_tag, {
                        type: "primary",
                        size: "medium"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.sampleList[0].code), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createElementVNode("span", _hoisted_7, [
                        _createVNode(_component_van_tag, {
                          color: "#ffe1e1",
                          "text-color": "#ad0000",
                          type: "primary"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getApplicationStatusDesc(item.status)), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ]),
                    _createElementVNode("p", _hoisted_8, [
                      _createTextVNode(" 样品名称：" + _toDisplayString(item.sampleList[0].name) + " ", 1),
                      _createElementVNode("span", _hoisted_9, "编制日期：" + _toDisplayString(_ctx.formatDataTime(item.compileTime)), 1)
                    ]),
                    _createElementVNode("p", _hoisted_10, [
                      _createTextVNode(" 检测目的：" + _toDisplayString(item.inspectGoal), 1),
                      _createElementVNode("span", _hoisted_11, "审核日期：" + _toDisplayString(_ctx.formatDataTime(item.approveTime)), 1)
                    ]),
                    _createElementVNode("p", _hoisted_12, [
                      _createElementVNode("span", _hoisted_13, "签发日期：" + _toDisplayString(item.signeTime), 1)
                    ])
                  ])
                ]),
                "right-icon": _withCtx(() => [
                  _createVNode(_component_van_icon, {
                    name: "arrow",
                    class: "arrow-icon",
                    size: "20",
                    onClick: ($event: any) => (_ctx.showApproval(item, idx))
                  }, null, 8, ["onClick"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["loading", "finished", "onLoad"])
      ]),
      _: 1
    }, 8, ["modelValue", "onRefresh"]),
    _createVNode(_component_van_action_bar, null, {
      default: _withCtx(() => [
        _createVNode(_component_van_checkbox, {
          class: "vanCheckBoxClazz",
          modelValue: _ctx.allChecked,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.allChecked) = $event)),
          onClick: _ctx.checkAllChange
        }, {
          default: _withCtx(() => [
            _createTextVNode("全选")
          ]),
          _: 1
        }, 8, ["modelValue", "onClick"]),
        _createVNode(_component_van_action_bar_button, {
          class: "vanButtonClazz",
          type: "success",
          text: "批量归档",
          onClick: _ctx.submitForm
        }, null, 8, ["onClick"])
      ]),
      _: 1
    }),
    _createVNode(_component_ArchivedReport, {
      ref: "approvalReportRef",
      onDoSearch: _ctx.doSearch
    }, null, 8, ["onDoSearch"]),
    _createVNode(_component_van_popup, {
      show: _ctx.show,
      "onUpdate:show": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.show) = $event)),
      position: "bottom",
      closeable: "",
      "close-icon-position": "top-right",
      teleport: "#app",
      onClose: _ctx.closePopup,
      style: { width: '100%', height: '60%' }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_cell_group, { title: "归档" }, {
          default: _withCtx(() => [
            _createVNode(_component_van_field, {
              modelValue: _ctx.approvalForm.remark,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.approvalForm.remark) = $event)),
              rows: "2",
              autosize: "",
              label: "意见",
              type: "textarea",
              maxlength: "50",
              placeholder: "请输入意见",
              "show-word-limit": ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_van_action_bar, null, {
          default: _withCtx(() => [
            _createVNode(_component_van_action_bar_button, {
              type: "primary",
              text: "归档",
              loading: _ctx.passing,
              onClick: _ctx.archivedPass
            }, null, 8, ["loading", "onClick"]),
            _createVNode(_component_van_action_bar_button, {
              type: "warning",
              loading: _ctx.returning,
              text: "回退",
              onClick: _ctx.archivedReturn
            }, null, 8, ["loading", "onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["show", "onClose"])
  ]))
}